import { faCalculator, faLightbulb } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CircularProgress, Typography } from "@material-ui/core"
import { useContext } from "react";
import { AppContext } from "../../Contexts/AppContext/AppContext";
import { UniversidadLoaderStyle } from "./UniversidadLoaderStyle"




export const UniversidadLoader = () => {

    const { AppState } = useContext(AppContext);
    const styles = UniversidadLoaderStyle();

    console.info("logo set", AppState.Universidad.logoUrl);

    const {
        QuickTips,
        Universidad
    } = AppState;

    return (
        <Box
            className={styles.bgContainer}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
        >
            {
                AppState.Universidad.nombre === "" ? (

                    <FontAwesomeIcon icon={faCalculator} size="6x" className={styles.defaultIcon} />

                ) : (
                    <img src={`${process.env.PUBLIC_URL}${Universidad.logoUrl}`} alt="logo-text"
                        className={styles.universidadLogo}
                    />
                )
            }
            <CircularProgress size={250} thickness={3} className={styles.circularColor} />
            {
                AppState.QuickTips.length > 0 &&
                <Typography variant="h5" className={styles.tipFormat}>
                    <FontAwesomeIcon icon={faLightbulb} size="2x" /> {QuickTips[Math.floor(Math.random() * QuickTips.length)].mensaje}
                </Typography>
            }
        </Box>
    )

}