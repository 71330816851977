import { LamarTheme } from "../../Theme/LamarTheme";
import { BaseStyleDefinition } from "./BaseStyleDefinition";


export class LamarStyleDefinition extends BaseStyleDefinition {

    constructor() {
        super(
            "lamar-style",
            LamarTheme
        );
    }

}