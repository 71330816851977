



export class GLOBALS {

    //public static API_URL: string = "https://nacer-calculadora.azurewebsites.net/core/api";
    public static API_URL: string = "https://ng-calculadora.azurewebsites.net/core/api";
    //public static API_URL: string = "https://calculatucolegiatura.com/core/api";
    //public static API_URL: string = "http://calculatucolegiatura.com/core/api";

    public static APP_VERSION: string = "1.10.0";
    public static MODALIDAD_ONLINE_ID: string = "392d11b5-cfb6-425c-94de-5fc84a493dda";

}