import axios from "axios";
import { IUniversidadData } from "../Interfaces/Data/IUniversidadData";
import { IAPIUniversidadConnector } from "../Interfaces/Services/IAPIUniversidadConnector";
import { APIBaseConnector } from "./APIBaseConnector";




export class APIUniversidadConnector extends APIBaseConnector implements IAPIUniversidadConnector {


    public async GetUniversidadById(id: string): Promise<IUniversidadData> {

        const response = await axios.get<IUniversidadData>(
            this.CreateActionURL(`universidad/${id}`)
        );

        console.info("Response from axios", response);

        return response.data;

    }

}