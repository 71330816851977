import { createTheme } from "@material-ui/core";


export const UAATheme = createTheme({
    palette: {
        primary: {
            main: "#019dcc",
            contrastText: "#fafafa"
        },
        secondary: {
            main: "#002161",
            contrastText: "#1a237e"
        },
        tertiary: {
            main: "#8b113e",
        }
    },
    typography: {
        fontFamily: [
            'Krub',
            'sans-serif'
        ].join(',')
    }
});