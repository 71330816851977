import { CssBaseline } from '@material-ui/core';
import { createBrowserHistory } from 'history';
import React from 'react';
import { Router } from 'react-router-dom';
import './App.css';
import { AppStyle } from './AppStyle';
import { APIContextProvider } from './Contexts/APIContext/APIContext';
import { AppContextProvider } from './Contexts/AppContext/AppContext';
import { StylerContextProvider } from './Contexts/StylerContext/StylerContext';
import { AppRouter } from './Route/AppRouter';
import { AppRoutes } from './Route/AppRoutes';

function App() {

  const history = createBrowserHistory();
  const style = AppStyle();

  return (

    <APIContextProvider>
      <AppContextProvider>
        <StylerContextProvider>
          <Router history={history}>
            <CssBaseline />
            <div className={style.mainContainer}>
              {
                AppRouter(AppRoutes)
              }
            </div>
          </Router>
        </StylerContextProvider>
      </AppContextProvider>
    </APIContextProvider>


  );
}

export default App;
