import { makeStyles, Theme } from "@material-ui/core";



export const CalculadoraButtonNavigatorStyle = makeStyles((theme: Theme) => ({

    navContainer: {
        marginTop: "auto",
        backgroundColor: theme.palette.secondary.main,
        backgroundImage: `url(${process.env.PUBLIC_URL}/bgTexture.png)`,
        borderTopLeftRadius: "1rem",
        borderTopRightRadius: "1rem",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        paddingBottom: "1rem",
        position: "fixed",
        bottom: 0,
        left: 0,

        [theme.breakpoints.between('sm', 'md')]: {
            paddingLeft: "5rem",
            paddingRight: "5rem"
        },

        [theme.breakpoints.up('lg')]: {
            paddingLeft: "25rem",
            paddingRight: "25rem"
        },

        [theme.breakpoints.up('xl')]: {
            paddingLeft: "35rem",
            paddingRight: "35rem"
        }

    },
    returnButton: {
        "&:hover": {
            backgroundColor: theme.palette.tertiary.main,
        },
        minHeight: "3.5rem",
        backgroundColor: theme.palette.tertiary.main,
        color: theme.palette.common.white
    },
    primaryButton: {
        minHeight: "3.5rem",
    }

}));