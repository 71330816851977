import { makeStyles, Theme } from "@material-ui/core";



export const CalculadoraBannerPromocionStyles = makeStyles((theme: Theme) => ({

    bannerStyles: {
        textAlign: "center",
        marginTop: "1rem",
        padding: "0.5rem",
        borderColor: theme.palette.primary.main,
        minHeight: "6rem",
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
    },
    bannerText: {
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
    }

}));