import { ColeVascoTheme } from "../../Theme/ColeVascoTheme";
import { BaseStyleDefinition } from "./BaseStyleDefinition";

export class ColeVascoStyleDefinition extends BaseStyleDefinition {

    constructor() {
        super(
            "colevasco-style",
            ColeVascoTheme
        );
    }

}