import { Theme } from "@material-ui/core";
import { IStyleDefinition } from "../../Interfaces/General/IStyleDefinition";





export class BaseStyleDefinition implements IStyleDefinition {

    private _nombre: string;
    private _themeRef: Theme;

    constructor(name: string, theme: Theme) {

        this._nombre = name;
        this._themeRef = theme;

    }

    public get themeRef(): Theme {
        return this._themeRef;
    }
    public set themeRef(value: Theme) {
        this._themeRef = value;
    }

    public get nombre(): string {
        return this._nombre;
    }
    public set nombre(value: string) {
        this._nombre = value;
    }


    public GetTheme(): Theme {

        return this.themeRef;

    }

    public IsCurrentStyle(name: string): boolean {

        return name === this.nombre;

    }


}