import { createTheme } from "@material-ui/core";


export const UNETheme = createTheme({
    palette: {
        primary: {
            main: "#cda752",
            contrastText: "#fafafa"
        },
        secondary: {
            main: "#006b5b",
            contrastText: "#1a237e"
        },
        tertiary: {
            main: "#0056bc",
        }
    },
    typography: {
        fontFamily: [
            'Krub',
            'sans-serif'
        ].join(',')
    }
});