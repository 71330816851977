import { UNIDEPTheme } from "../../Theme/UNIDEPTheme";
import { BaseStyleDefinition } from "./BaseStyleDefinition";


export class UNIDEPStyleDefinition extends BaseStyleDefinition {

    constructor() {
        super(
            "unidep-style",
            UNIDEPTheme
        );
    }

}