import { BritanicaTheme } from "../../Theme/BritanicaTheme";
import { BaseStyleDefinition } from "./BaseStyleDefinition";


export class BritanicaStyleDefinition extends BaseStyleDefinition {

    constructor() {
        super(
            'britanica-style',
            BritanicaTheme
        );
    }

}