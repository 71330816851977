import { createTheme } from "@material-ui/core";



export const DefaultTheme = createTheme({
    palette: {
        primary: {
            main: "#C0C0C0",
            contrastText: "#fafafa"
        },
        secondary: {
            main: "#FF9C01",
            contrastText: "#1a237e"
        },
        tertiary: {
            main: "#FF9C01",
        }

    },
    typography: {
        fontFamily: [
            'Krub',
            'sans-serif'
        ].join(',')
    }
});