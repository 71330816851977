import { makeStyles, Theme } from "@material-ui/core";



export const CalculadoraHeaderStyle = makeStyles((theme: Theme) => ({

    headerContainer: {
        minHeight: "calc(15rem + 2vmin)",
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        padding: "1rem",
        backgroundImage: `url(${process.env.PUBLIC_URL}/bgTexture.png)`,
        backgroundColor: theme.palette.secondary.main,
        paddingBottom: "6rem",

        paddingLeft: "0rem",
        paddingRight: "0rem",

        [theme.breakpoints.between('sm', 'md')]: {
            paddingLeft: "5rem",
            paddingRight: "5rem"
        },

        [theme.breakpoints.up('lg')]: {
            paddingLeft: "25rem",
            paddingRight: "25rem"
        },

        [theme.breakpoints.up('xl')]: {
            paddingLeft: "35rem",
            paddingRight: "35rem"
        }

    },


}));