import { createTheme } from "@material-ui/core";


export const UNIJVTheme = createTheme({
    palette: {
        primary: {
            main: "#2b9125",
            contrastText: "#fafafa"
        },
        secondary: {
            main: "#3274c0",
            contrastText: "#ffffff"
        },
        tertiary: {
            main: "#96bc48",
        }
    },
    typography: {
        fontFamily: [
            'Krub',
            'sans-serif'
        ].join(',')
    }
});