import axios from "axios";
import { IUserCollectedData } from "../Interfaces/Data/IUserCollectedData";
import { IAPIContactoConnector } from "../Interfaces/Services/IAPIContactoConnector";
import { APIBaseConnector } from "./APIBaseConnector";



export class APIContactoConnector extends APIBaseConnector implements IAPIContactoConnector {


    public async CreateContacto(user: IUserCollectedData): Promise<IUserCollectedData> {

        const response = await axios.post<IUserCollectedData>(
            this.CreateActionURL("Contacto"),
            {
                "nombre": user.nombre,
                "telefono": user.telefono,
                "contactar": true
            }
        )

        console.info("contacto response", response.data);
        return response.data;

    }


}