import { createTheme } from "@material-ui/core";


export const SantaFeTheme = createTheme({
    palette: {
        primary: {
            main: "#af2c31",
            contrastText: "#fff"
        },
        secondary: {
            main: "#252058",
            contrastText: "#fff"
        },
        tertiary: {
            main: "#c1921e",
        }
    },
    typography: {
        fontFamily: [
            'Krub',
            'sans-serif'
        ].join(',')
    }
});