import { createContext, useState } from "react";
import { IAPIQueryDataConnector } from "../../Interfaces/Services/IAPIQueryDataConnector";
import { GLOBALS } from "../../Model/GLOBALS";
import { APIQueryDataConnector } from "../../Services/APIQueryDataConnector";
import { IAPIContextHandlers } from "./IAPIContextHandlers";
import { IAPIContextProviderProps } from "./IAPIContextProviderProps";





export const APIContext = createContext<IAPIContextHandlers>({} as IAPIContextHandlers);


export const APIContextProvider = ({ children }: IAPIContextProviderProps) => {

    const [apiService, setApiService] = useState<IAPIQueryDataConnector>(new APIQueryDataConnector(GLOBALS.API_URL));

    const updateApiService = (service: IAPIQueryDataConnector) => {
        setApiService(service);
    };

    return (

        <APIContext.Provider value={{ APIService: apiService, UpdateApiService: updateApiService }}>
            {children}
        </APIContext.Provider>

    );

}