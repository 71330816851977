import axios from "axios";
import { INivelEducativoData } from "../Interfaces/Data/INivelEducativoData";
import { IAPINivelEducativoConnector } from "../Interfaces/Services/IAPINivelEducativoConnector";
import { APIBaseConnector } from "./APIBaseConnector";




export class APINivelEducativoConnector extends APIBaseConnector implements IAPINivelEducativoConnector {


    public async GetNivelesDisponibles(idUniversidad: string): Promise<INivelEducativoData[]> {

        const response = await axios.get<INivelEducativoData[]>(
            this.CreateActionURL(`NivelEducativo/ByUniversidad/${idUniversidad}`)
        );

        return response.data;

    }

}