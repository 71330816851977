import { faSearch } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListItem, ListItemText, ListItemSecondaryAction, Radio, Grid, Typography, OutlinedInput, InputAdornment, List, Divider, ListItemAvatar, Avatar } from "@material-ui/core";
import { useContext, useState } from "react";
import { APIContext } from "../../Contexts/APIContext/APIContext";
import { AppContext } from "../../Contexts/AppContext/AppContext";
import { IRecintoData } from "../../Interfaces/Data/IRecintoData";
import { CalculadoraPlantelFilterStyle } from "./CalculadoraPlantelFilterStyle";
import { ICalculadoraPlantelProps } from "./ICalculadoraPlantelProps";


export const CalculadoraPlantelFilter = ({ Planteles }: ICalculadoraPlantelProps) => {

    const styles = CalculadoraPlantelFilterStyle();

    const { APIService } = useContext(APIContext);
    const { AppState, UpdatePlantel } = useContext(AppContext);

    const [filter, setFilter] = useState("");


    const {
        Campus
    } = AppState;

    const updateFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(event.target.value);
    };

    const plantelChosen = (value: IRecintoData) => () => {

        UpdatePlantel(value);

    };

    const isCurrentSelection = (plantel: IRecintoData) => {

        return plantel.chainId === Campus.chainId;

    }


    const renderList = (plantel: IRecintoData, index: number) => {

        const itemKey = `plantel-list-item-${index}`;
        const labelId = `plantel-list-label-${index}`;

        return (
            <div key={itemKey}>
                <ListItem className={isCurrentSelection(plantel) ? styles.itemContainerSelected : styles.itemContainer}>
                    <ListItemAvatar>
                        <Avatar src={plantel.imageUrl} variant="square" className={styles.avatarImage} />
                    </ListItemAvatar>
                    <ListItemText id={labelId}
                        primary={
                            <>
                                <Typography variant="h6">
                                    {plantel.nombre}
                                </Typography>
                            </>
                        }
                        secondary={
                            <>
                                <Typography variant="body2">
                                    {`${plantel.calle} #${plantel.numero}`}
                                </Typography>
                                <Typography variant="body2">
                                    {`${plantel.colonia} CP: ${plantel.codigoPostal}`}
                                </Typography>
                                <Typography variant="body2">
                                    {`${plantel.referencia}`}
                                </Typography>
                            </>
                        }
                        className={styles.listText} />
                    <ListItemSecondaryAction>
                        <Radio
                            edge="end"
                            checked={isCurrentSelection(plantel)}
                            onChange={plantelChosen(plantel)}
                            inputProps={{ 'aria-labelledby': labelId }}
                            name="plantel-radio-list"
                        />
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider />
            </div>
        );

    };


    const onFilteredRender = () => {

        const toFilter = Planteles.filter(item => {

            return item.nombre.toLowerCase().includes(filter.toLowerCase());

        })

        if (toFilter.length > 0) {

            return toFilter.map((value, index) => {

                return renderList(value, index);

            });

        }

        return (
            <Grid container className={styles.noResultContainer}>
                <Grid item xs={12} container justifyContent="center" alignContent="center">
                    <Typography variant="h6">
                        Sin resultados que mostrar.
                    </Typography>
                </Grid>
            </Grid>
        );


    }


    return (

        <>
            <Grid container>
                <Grid container item xs={12} justifyContent="center" className={styles.plantelFilterTitle}>
                    <Typography variant="h6">
                        Planteles con {AppState.Carrera.nombre}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <OutlinedInput
                        fullWidth
                        type="text"
                        value={filter}
                        onChange={updateFilter}
                        startAdornment={
                            <InputAdornment position="start">
                                <FontAwesomeIcon icon={faSearch} />
                            </InputAdornment>
                        }
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item container xs={12} justifyContent="center" alignContent="center" alignItems="center" className={styles.plantelFilterTitle}>
                    <Typography variant="h6">
                        Selecciona el Plantel de Interés
                    </Typography>
                </Grid>
                <Grid item xs={12} className={styles.listContainer}>
                    <List dense>
                        {
                            filter !== "" ? (

                                onFilteredRender()

                            ) : (

                                Planteles.map((value, index) => {

                                    return renderList(value, index);

                                })

                            )
                        }
                    </List>
                </Grid>
            </Grid>
        </>

    );

};