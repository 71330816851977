import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Paper, Typography } from "@material-ui/core";
import { useContext } from "react";
import { AppContext } from "../../Contexts/AppContext/AppContext";
import { GAnalReporter } from "../../Helpers/GAnalReporter";
import { GAEVENTNAMES } from "../../Model/GAEVENTNAMES";
import { GAReporterDataContanier } from "../../Model/GAReportDataContainer";
import { APIWebHookConnector } from "../../Services/APIWebHookConnector";
import { CalculadoraWhatsAppLinkProps } from "./CalculadoraWhatsAppLinkProps";
import { CalculadoraWhatsAppStyles } from "./CalculadoraWhatsAppStyles";



export const CalculadoraWhatsAppLink = ({ costoTotal }: CalculadoraWhatsAppLinkProps) => {

    const { AppState } = useContext(AppContext);
    const styles = CalculadoraWhatsAppStyles();

    const {
        mensajesPromos,
        nombre,
        crmUrl,
        chainId
    } = AppState.Universidad;

    const {
        whatsApp
    } = AppState.Campus;

    const formatWhatsMessage = (): string => {

        let message = createMessage();
        return encodeURIComponent(message);

    };


    const createEspecialMessage = (baseLinea: string, modalidad: string): string => {

        if (chainId !== "7033102e-e0f5-4a2c-a8e9-10c694f0bd2a") {
            return baseLinea;
        }

        if (baseLinea === "Licenciatura Escolarizada") {

            switch (modalidad) {
                case "Mixto":
                    return "Licenciatura Ejecutiva";
                case "Sabatino":
                    return "Licenciatura Ejecutiva";
                case "Online":
                    return "Licenciatura Online";
                default:
                    return baseLinea;
            }

        }

        if (baseLinea === "Ciencias de la Salud") {

            switch (modalidad) {
                case "Nivelatorio":
                    return "Nivelatorio";
                default:
                    return baseLinea;
            }

        }

        if (baseLinea === "Posgrado") {

            switch (modalidad) {
                case "Online":
                    return "Posgrado Online";
                default:
                    return baseLinea;
            }

        }

        return baseLinea;

    }


    const createMessage = (): string => {

        const canal = AppState.OriginURL ? AppState.OriginURL : "Sitio Web";
        let message = `Hola vengo de su ${canal}. Me podrían proporcionar información de `;
        message += `${AppState.Carrera.nombre} en su modalidad ${createEspecialMessage(AppState.LineaNegocio.equivalencia, AppState.Modalidad.nombre)}`;
        if (AppState.Campus.chainId != "") {
            message += ` en el plantel ${AppState.Campus.nombre}`;
        }
        message += ` [Calculadora]`;
        return message;

    };


    const ReportGA = async (): Promise<void> => {

        GAnalReporter(
            GAEVENTNAMES.CLICK_WHATS_APP,
            new GAReporterDataContanier(
                nombre,
                1
            ).ToJSON()
        );
        let crmReporter = new APIWebHookConnector();
        await crmReporter.CreateLeadRecord(
            crmUrl,
            nombre,
            AppState.UserData.nombre,
            AppState.UserData.telefono,
            AppState.LineaNegocio.equivalencia,
            AppState.Modalidad.nombre,
            AppState.Carrera.nombre,
            window.location.href,
            createMessage(),
            AppState.Campus.nombre,
            costoTotal,
            AppState.OriginURL ? AppState.OriginURL : "",
        );

    };


    return (
        <>
            {
                whatsApp &&
                <Paper variant="outlined" className={styles.bannerStyles}>
                    {
                        mensajesPromos &&
                        mensajesPromos.map((promo, index) => (
                            <Typography key={`promomsg-${index}`} variant="h6">
                                {promo.mensaje}
                            </Typography>
                        ))
                    }
                    <Button
                        color="primary"
                        variant="contained"
                        target="_blank"
                        href={`https://wa.me/521${whatsApp}?text=${formatWhatsMessage()}`}
                        onClick={ReportGA}
                        className={styles.ButtonWhatsApp}
                    >
                        <Typography variant="subtitle1">
                            <FontAwesomeIcon icon={faWhatsapp} size="2x" /> Quiero más información
                        </Typography>
                    </Button>
                </Paper>
            }
        </>
    );

};