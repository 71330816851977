import { makeStyles, Theme } from "@material-ui/core";


export const CalculadoraPlantelFilterStyle = makeStyles((theme: Theme) => ({

    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    listText: {
        color: theme.palette.secondary.main,
        '& span, & svg': {
            fontSize: '1.2rem'
        }
    },
    noResultContainer: {
        minHeight: "14rem"
    },
    listContainer: {
        marginBottom: "1rem",
        marginTop: "1rem",
        minHeight: "19rem"
    },
    itemContainer: {
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem"
    },
    itemContainerSelected: {
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
        backgroundColor: `${theme.palette.tertiary.main}0f`,
    },
    avatarImage: {
        width: "150px",
        height: "150px",
        borderRadius: "1rem",
        marginRight: "1rem",
        marginLeft: 0
    },
    plantelFilterTitle: {
        minHeight: "5rem",
    }
})
);