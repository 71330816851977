import { Button, ButtonGroup, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { useContext } from "react";
import { AppContext } from "../../Contexts/AppContext/AppContext";
import { GAnalReporter } from "../../Helpers/GAnalReporter";
import { IModalidadData } from "../../Interfaces/Data/IModalidadData";
import { GAEVENTNAMES } from "../../Model/GAEVENTNAMES";
import { GAReporterDataContanier } from "../../Model/GAReportDataContainer";
import { ICalculadoraModalidadSelectorProps } from "./ICalculadoraModalidadSelectorProps";




export const CalculadoraModalidadSelector = ({ Modalidades }: ICalculadoraModalidadSelectorProps) => {

    const orientationGroup = useMediaQuery('(min-width:600px)');

    const {
        AppState,
        UpdateModalidad
    } = useContext(AppContext);

    const setModalidad = (modalidad: IModalidadData) => {

        UpdateModalidad(modalidad);
        GAnalReporter(
            GAEVENTNAMES.SELECCION_MODALIDAD,
            new GAReporterDataContanier(
                AppState.Universidad.nombre,
                modalidad.nombre
            ).ToJSON()
        );

    }


    return (

        <Grid container style={{ marginBottom: "0.5rem" }}>
            <Grid container item xs={12} justifyContent="center">
                <Typography variant="h6">
                    {`¿En qué modalidad quieres estudiar la ${AppState.LineaNegocio.nombre}?`}
                </Typography>
            </Grid>
            <Grid container item xs={12} justifyContent="center">
                <ButtonGroup color="primary" orientation={`${orientationGroup ? 'horizontal' : 'vertical'}`}>
                    {
                        Modalidades.map((mod, index) => (
                            <Button key={`modalidad-${index}`} variant={AppState.Modalidad.chainId === mod.chainId ? "contained" : "outlined"} onClick={() => { setModalidad(mod) }}>
                                {mod.nombre}
                            </Button>
                        ))
                    }
                </ButtonGroup>
            </Grid>
        </Grid>

    );


};