import { makeStyles, Theme } from "@material-ui/core";



export const CalculadoraCarreraFilterStyle = makeStyles((theme: Theme) => ({

    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    listText: {
        color: theme.palette.secondary.main,
        '& span, & svg': {
            fontSize: '1.2rem'
        }
    },
    noResultContainer: {
        minHeight: "14rem"
    },
    listContainer: {
        marginBottom: "1rem",
        marginTop: "1rem"
    },
    itemContainer: {
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem"
    },
    itemContainerSelected: {
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
        backgroundColor: `${theme.palette.tertiary.main}0f`,
    },
    circleIcon: {
        background: "#00c1bc",
        color: "white",
        width: "100px",
        height: "100px",
        borderRadius: "50%",
        textAlign: "center",
        lineHeight: "100px",
        verticalAlign: "middle",
        padding: "30px",
    },
    noDataContainer: {
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
    }
})
);