import { makeStyles, Theme } from "@material-ui/core";



export const AppStyle = makeStyles((theme: Theme) => ({

    mainContainer: {

        backgroundColor: "#252525",
        minHeight: "100vh",
        maxWidth: "100vw",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        paddingLeft: "0rem",
        paddingRight: "0rem",
        paddingBottom: "65px"

        // [theme.breakpoints.between('sm', 'md')]: {
        //     paddingLeft: "5rem",
        //     paddingRight: "5rem"
        // },

        // [theme.breakpoints.up('lg')]: {
        //     paddingLeft: "10rem",
        //     paddingRight: "10rem"
        // }

    }

}));