import { IAPIBaseContextConnector } from "../Interfaces/Services/IAPIBaseContextConnector";




export class APIBaseConnector implements IAPIBaseContextConnector {

    private _BaseURL: string;

    constructor(baseURL: string) {

        this._BaseURL = baseURL;

    }


    /**
     * Genera la url de la accion ingresada, no es necesario enviar la primera diagonal (/) de la accion.     
     * @param action nombre de la accion de la api a consultar
     * @returns Url formada
     */
    public CreateActionURL(action: string): string {

        return `${this.BaseURL}/${action}`

    }


    public get BaseURL(): string {
        return this._BaseURL;
    }
    public set BaseURL(value: string) {
        this._BaseURL = value;
    }


}