import { makeStyles, Theme } from "@material-ui/core";



export const LineaNegocioRenderStyle = makeStyles((theme: Theme) => ({

    textCentered: {
        color: theme.palette.common.white,
        flexGrow: 1,
        marginBottom: "2rem",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        display: "flex"
    },
    borderedBottom: {
        borderBottomLeftRadius: "1rem",
        borderBottomRightRadius: "1rem"
    },
    buttonContainer: {
        borderRadius: "1rem",
        backgroundColor: theme.palette.primary.main,
        margin: "1rem",
        flexGrow: 1,
        "& .MuiImageListItem-item": {
            display: "flex"
        }
    },
    buttonContainerSelected: {
        borderRadius: "1rem",
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.tertiary.main,
        border: "0.4rem solid",
        margin: "1rem",
        flexGrow: 1,
        "& .MuiImageListItem-item": {
            display: "flex"
        }
    },
    listContainer: {
    },
    notSelectedIcon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
    selectedIcon: {
        color: theme.palette.tertiary.main
    },
    selectedImage: {
        color: theme.palette.tertiary.main,
    }

}));