import { LucernaTheme } from "../../Theme/LucernaTheme";
import { BaseStyleDefinition } from "./BaseStyleDefinition";




export class LucernaStyleDefinition extends BaseStyleDefinition {

    constructor() {
        super(
            "lucerna-style",
            LucernaTheme
        );
    }


}