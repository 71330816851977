import axios from "axios";
import moment from "moment";
import { IAPIAutomateConnector } from "../Interfaces/Services/IAPIAutomateConnector";


export class APIWebHookConnector implements IAPIAutomateConnector {


    public async CreateLeadRecord(urlPost: string, universidad: string, nombre: string, telefono: string, nivelEducativo: string,
        modalidad: string, carrera: string, currentUrl: string, mensajeDinamico: string, campus: string, precio: number, origin: string): Promise<void> {

        const response = await axios.post(urlPost,
            {
                "universidad": universidad,
                "medio": "Digital",
                "nombre_completo": nombre,
                "campus": campus,
                "costo_lista": precio,
                "telefono": telefono,
                "nivel_educativo": nivelEducativo,
                "modalidad": modalidad,
                "carrera": carrera,
                "date_formatted": moment().format("DD/MM/YYYY"),
                "data_CRM": mensajeDinamico,
                "url": currentUrl,
                "utm_source": "",
                "utm_medium": "",
                "utm_campaign": "",
                "utm_term": "",
                "utm_content": "",
                "canal": origin === "" ? "Sitio Web" : origin,
                "origen": "Conversacion",
                "suborigen": "Calculadora"
            }

        );

        return;

    }


}