import { createTheme } from "@material-ui/core";


export const UniverGDLTheme = createTheme({
    palette: {
        primary: {
            main: "#1c4096",
            contrastText: "#fafafa"
        },
        secondary: {
            main: "#0d0049",
            contrastText: "#1a237e"
        },
        tertiary: {
            main: "#ec316a",
        }
    },
    typography: {
        fontFamily: [
            'Krub',
            'sans-serif'
        ].join(',')
    }
});