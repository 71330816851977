import { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CalculadoraButtonNavigator } from "../../Components/CalculadoraButtonNavigator/CalculadoraButtonNavigator";
import { CalculadoraCarreraFilter } from "../../Components/CalculadoraCarreraFilter/CalculadoraCarreraFilter";
import { CalculadoraDatosContacto } from "../../Components/CalculadoraDatosContacto/CalculadoraDatosContacto";
import { CalculadoraModalidadSelector } from "../../Components/CalculadoraModalidadSelector/CalculadoraModalidadSelector";
import { CalculadoraPlantelFilter } from "../../Components/CalculadoraPlantelFilter/CalculadoraPlantelFilter";
import { CalculadoraResultData } from "../../Components/CalculadoraResultData/CalculadoraResultData";
import { LineaNegocioRender } from "../../Components/LineaNegocioRender/LineaNegocioRender";
import { UniversidadLoader } from "../../Components/UniversidadLoader/UniversidadLoader";
import { APIContext } from "../../Contexts/APIContext/APIContext";
import { AppContext } from "../../Contexts/AppContext/AppContext";
import { StylerContext } from "../../Contexts/StylerContext/StylerContext";
import { SECTIONS_IDS } from "../../Enums/SectionsIds";
import { GAnalReporter } from "../../Helpers/GAnalReporter";
import { UseCalculadoraRender } from "../../Hooks/UseCalculadoraRender/UseCalculadoraRender";
import { ICarreraConfigData } from "../../Interfaces/Data/ICarreraConfigData";
import { GAEVENTNAMES } from "../../Model/GAEVENTNAMES";
import { GAReporterDataContanier } from "../../Model/GAReportDataContainer";
import { GLOBALS } from "../../Model/GLOBALS";
import { CalculadoraQuestionLayout } from "../CalculadoraQuestionLayout/CalculadoraQuestionLayout";
import { ICalculadoraUsuarioLayoutState } from "./ICalculadoraUsuarioLayoutState";




export const CalculadoraUsuarioLayout = () => {

    const didMount = useRef(false);
    const { UpdateStyler } = useContext(StylerContext);
    const { APIService } = useContext(APIContext);
    const {
        AppState,
        UpdateInitialData,
        UpdateFullUserData,
        UpdatePlantel,
        ClearData
    } = useContext(AppContext);

    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const { idUniversidad } = useParams<{ idUniversidad: string }>();
    const history = useHistory();

    const [currentSection, SetSection] = UseCalculadoraRender(SECTIONS_IDS.CONTACTO_SECTION);
    const [layoutState, setLayoutState] = useState<ICalculadoraUsuarioLayoutState>({

        IsLoading: true,
        ModalidadesOptions: [],
        LineasNegocio: [],
        PlantelesOptions: [],
        ResultData: []

    });


    const {
        Universidad,
        Campus
    } = AppState;

    const {
        CurrentSection
    } = currentSection;

    const {
        IsLoading,
        ModalidadesOptions,
        PlantelesOptions,
        ResultData
    } = layoutState;


    const LoadUniversidadData = async () => {

        try {

            const uniData = await APIService.Universidades.GetUniversidadById(idUniversidad);
            const quickTips = await APIService.QuickTips.GetTips(idUniversidad);
            console.info("Response", uniData);
            console.info("Target Return:", queryParams.get('target'), queryParams.get('origin'));
            UpdateInitialData(uniData, quickTips, queryParams.get('target'), queryParams.get('origin'));
            UpdateStyler(uniData.cssName);


        } catch (error) {

            console.info("Redireccionar hme");
            history.push('/');

        }

    };


    const SendToCRM = async (resultsData: ICarreraConfigData[]): Promise<void> => {

        let NotasMessage = `El usuario con telefono ${AppState.UserData.telefono}, solicito información de la linea de negocio: ${AppState.LineaNegocio.nombre} en la carrera: ${AppState.Carrera.nombre}`;

        if (AppState.Campus.chainId != "") {
            NotasMessage += ` en el campus: ${AppState.Campus.nombre}`;
        }

        NotasMessage += ` en la modalidad: ${AppState.Modalidad.nombre}`;

        const response = await APIService.CRMConnector.CreateLeadRecord(
            AppState.Universidad.nombreCRM,
            AppState.UserData.nombre,
            AppState.UserData.telefono,
            NotasMessage
        );

        console.info("Response CRM", response);

    };


    const LoadResultData = async (): Promise<ICarreraConfigData[]> => {

        let result;

        // if (AppState.Campus.chainId != "") {

        console.info("AppState Campus", AppState.Campus)

        result = await APIService.CarreraConfig.GetConfigCarreraUniversidad(
            AppState.Universidad.chainId,
            AppState.Modalidad.chainId,
            AppState.Carrera.chainId,
            AppState.Campus.chainId
        );

        // } else {

        //     result = await APIService.CarreraConfig.GetConfigCarreraUniversidad(
        //         AppState.Universidad.chainId,
        //         AppState.Modalidad.chainId,
        //         AppState.Carrera.chainId
        //     );
        // }

        result.map(async (record) => {

            const response = await APIService.Cotizacion.CreateUserCotizacion(
                AppState.UserData.chainId,
                record.id
            );

        });

        //await SendToCRM(result);

        return result;
    };


    useEffect(() => {

        LoadUniversidadData();

    }, []);

    useEffect(() => {

        if (didMount.current) {
            setLayoutState({
                ...layoutState,
                IsLoading: false
            });
        } else {
            didMount.current = true;
        }

    }, [Universidad]);



    const nextLineaNegocio = async () => {

        setLayoutState({
            ...layoutState,
            IsLoading: true,
        });

        const userData = await APIService.Contactos.CreateContacto(AppState.UserData);
        UpdateFullUserData(userData);
        SetSection(SECTIONS_IDS.LINEA_NEGOCIO_SECTION);
        setLayoutState({
            ...layoutState,
            IsLoading: false,
        });
        GAnalReporter(
            GAEVENTNAMES.NUEVO_CONTACTO,
            new GAReporterDataContanier(
                Universidad.nombre,
                1
            ).ToJSON()
        );

    }


    const nextModalidad = async () => {

        setLayoutState({
            ...layoutState,
            IsLoading: true,
        });

        const modalidadesSupported = await APIService.Modalidad.GetModalidadesByNivelEducativo(
            AppState.LineaNegocio.chainId,
            AppState.Universidad.chainId
        );

        console.info("Modalidades", modalidadesSupported);

        SetSection(SECTIONS_IDS.MODALIDAD_SECTION);
        setLayoutState({
            ...layoutState,
            ModalidadesOptions: modalidadesSupported,
            IsLoading: false,
        });
    };



    const renderResult = async () => {

        setLayoutState({
            ...layoutState,
            IsLoading: true
        });
        const response = await LoadResultData();
        setLayoutState({
            ...layoutState,
            IsLoading: false,
            ResultData: response
        });

    };


    useEffect(() => {

        console.info("Calling reuslt", ResultData);

        if (didMount.current && ResultData.length > 0) {
            SetSection(SECTIONS_IDS.RESULTADO_SECTION);
        }

    }, [ResultData]);


    useEffect(() => {

        if (didMount.current) {

            console.info("Ingresando a Campus CurrentSection")
            if (CurrentSection == SECTIONS_IDS.RESULTADO_SECTION && ResultData.length == 0) {
                renderResult();
            }

        } else {
            didMount.current = true;
        }

    }, [Campus, CurrentSection]);


    const nextPlantelOrResult = async () => {

        setLayoutState({
            ...layoutState,
            IsLoading: true,
        });

        // if (AppState.Modalidad.chainId !== GLOBALS.MODALIDAD_ONLINE_ID) {


        // const recintosFound = await APIService.Carreras.GetRecintosByCarrera(
        //     AppState.Carrera.chainId,
        //     AppState.Universidad.chainId
        // );

        const recintosFound = await APIService.Carreras.GetRecintosByCarreraModalidad(
            AppState.Modalidad.chainId,
            AppState.Carrera.chainId,
            AppState.Universidad.chainId
        );

        console.info("recintos encontrados", recintosFound, layoutState);

        if (recintosFound.length > 1) {

            SetSection(SECTIONS_IDS.PLANTEL_SECTION);
            setLayoutState({
                ...layoutState,
                IsLoading: false,
                PlantelesOptions: recintosFound
            });

        } else {

            UpdatePlantel(recintosFound[0]);
            SetSection(SECTIONS_IDS.RESULTADO_SECTION);

        }

        // } else {

        //     await renderResult();

        // }

    };




    const nextDataCollector = () => {

        setLayoutState({
            ...layoutState,
            IsLoading: true,
        });

        setTimeout(() => {
            setLayoutState({
                ...layoutState,
                IsLoading: false,
            });
            SetSection(SECTIONS_IDS.CONTACTO_SECTION);
        }, 2500);

    };

    const backPlantelOrCarrera = () => {

        // if (AppState.Modalidad.chainId !== GLOBALS.MODALIDAD_ONLINE_ID) {

        //     SetSection(SECTIONS_IDS.PLANTEL_SECTION);

        // } else {

        SetSection(SECTIONS_IDS.MODALIDAD_SECTION);

        // }

    };

    const nextResult = async () => {

        await renderResult();

    };

    useEffect(() => {

        if (!IsLoading) {
            console.info("DONE LOADING", CurrentSection);
        }

    }, [IsLoading]);


    const clearData = () => {

        ClearData();
        setLayoutState({
            ...layoutState,
            ResultData: []
        });
        SetSection(SECTIONS_IDS.LINEA_NEGOCIO_SECTION)

    }


    return (

        <>
            {
                layoutState.IsLoading ? (

                    <UniversidadLoader />

                ) : (
                    <>
                        {
                            CurrentSection === SECTIONS_IDS.CONTACTO_SECTION &&
                            <CalculadoraQuestionLayout
                                Title="Información de Contacto"
                            >
                                <CalculadoraDatosContacto />
                                <CalculadoraButtonNavigator
                                    OnBackEvent={backPlantelOrCarrera}
                                    ShowNextButton={
                                        AppState.UserData.nombre !== "" && AppState.UserData.telefono !== "" &&
                                        AppState.UserData.telefono.length === 10
                                    }
                                    OnNextEvent={nextLineaNegocio}
                                />
                            </CalculadoraQuestionLayout>
                        }
                        {
                            CurrentSection === SECTIONS_IDS.LINEA_NEGOCIO_SECTION &&
                            <CalculadoraQuestionLayout
                                Title="Nivel Educativo"
                            >
                                <LineaNegocioRender />
                                <CalculadoraButtonNavigator
                                    ShowNextButton={AppState.LineaNegocio.chainId !== ""}
                                    OnNextEvent={nextModalidad}
                                />
                            </CalculadoraQuestionLayout>
                        }
                        {
                            CurrentSection === SECTIONS_IDS.MODALIDAD_SECTION &&
                            <CalculadoraQuestionLayout
                                Title="Modalidad y Carrera"
                            >
                                <CalculadoraModalidadSelector
                                    Modalidades={ModalidadesOptions}
                                />
                                <CalculadoraCarreraFilter />
                                <CalculadoraButtonNavigator
                                    ShowBackButton={true}
                                    OnBackEvent={() => { SetSection(SECTIONS_IDS.LINEA_NEGOCIO_SECTION) }}
                                    ShowNextButton={AppState.Modalidad.chainId !== "" && AppState.Carrera.chainId !== ""}
                                    OnNextEvent={nextPlantelOrResult}
                                />
                            </CalculadoraQuestionLayout>
                        }
                        {
                            (CurrentSection === SECTIONS_IDS.PLANTEL_SECTION && AppState.Modalidad) &&
                            <CalculadoraQuestionLayout
                                Title="Planteles Disponibles"
                            >
                                <CalculadoraPlantelFilter
                                    Planteles={PlantelesOptions}
                                />
                                <CalculadoraButtonNavigator
                                    ShowBackButton={true}
                                    OnBackEvent={() => { SetSection(SECTIONS_IDS.MODALIDAD_SECTION) }}
                                    ShowNextButton={AppState.Campus.chainId !== ""}
                                    OnNextEvent={nextResult}
                                />
                            </CalculadoraQuestionLayout>
                        }
                        {
                            CurrentSection === SECTIONS_IDS.RESULTADO_SECTION &&
                            <CalculadoraQuestionLayout
                                Title="Detalle de la colegiatura"
                            >
                                <CalculadoraResultData
                                    Results={ResultData}
                                />
                                <CalculadoraButtonNavigator
                                    ShowBackButton={true}
                                    BackLabelText="Consultar de Nuevo"
                                    OnBackEvent={clearData}
                                    ShowNextButton={false}
                                    ShowRegresarSitio={true}
                                />

                            </CalculadoraQuestionLayout>
                        }
                    </>
                )
            }
        </>

    );

};