


export class SECTIONS_IDS {

    public static LINEA_NEGOCIO_SECTION: string = "c-sec-linea-negocio";
    public static MODALIDAD_SECTION: string = "c-sec-modalidad";
    public static PLANTEL_SECTION: string = "c-sec-plantel";
    public static CONTACTO_SECTION: string = "c-sec-contacto";
    public static RESULTADO_SECTION: string = "c-sec-resultados";

}