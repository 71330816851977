import { UNIJVTheme } from "../../Theme/UNIJVTheme";
import { BaseStyleDefinition } from "./BaseStyleDefinition";

export class UNIJVStyleDefinition extends BaseStyleDefinition {

    constructor() {
        super(
            "unijv-style",
            UNIJVTheme
        );
    }

}