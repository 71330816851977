import { IAppContextState } from "../Contexts/AppContext/IAppContextState";




export const DefaultAppState: IAppContextState = {

    TargetURL: null,
    OriginURL: null,
    Universidad: {
        created: "",
        updated: "",
        clave: "",
        chainId: "",
        logoUrl: "",
        nombre: "",
        cssName: "",
        politicaURL: "#",
        banners: [],
        quickTips: [],
        recintos: [],
        mensajesPromos: [],
        whatsApp: "",
        showColegiatura: false,
        showPagoTotal: false,
        showPrimerPago: false,
        nombreCRM: "",
        crmUrl: ""
    },
    LineaNegocio: {
        equivalencia: "",
        created: "",
        updated: "",
        clave: "",
        chainId: "",
        nombre: "",
        prioridad: 0
    },
    Modalidad: {
        created: "",
        updated: "",
        clave: "",
        chainId: "",
        nombre: "",
    },
    Carrera: {
        created: "",
        updated: "",
        clave: "",
        chainId: "",
        nombre: "",
        imagenUrl: ""
    },
    Campus: {
        nombre: "",
        calle: "",
        numero: "",
        colonia: "",
        delegacion: "",
        codigoPostal: "",
        imageUrl: "",
        referencia: "",
        chainId: "",
        created: "",
        updated: "",
        whatsApp: "",
    },
    UserData: {
        nombre: "",
        telefono: "",
        correo: "",
        chainId: "",
        created: "",
        updated: "",
    },
    QuickTips: []


}