import { makeStyles, Theme } from "@material-ui/core";



export const CalculadoraQuestionLayoutStyle = makeStyles((theme: Theme) => ({


    mainAreaContent: {

        display: "flex",
        alignContent: "center",
        justifyContent: "flex-start",
        alignItems: "center",
        flexGrow: 1,
        flexDirection: "column",
        marginTop: "-4.5rem",
        borderRadius: "1rem",
        backgroundColor: "white",
        paddingTop: "2rem",
        paddingBottom: "3.5rem",
        paddingRight: "0.5rem",
        paddingLeft: "0.5rem",
        position: "relative",

        [theme.breakpoints.between('sm', 'md')]: {
            paddingLeft: "5rem",
            paddingRight: "5rem"
        },

        [theme.breakpoints.up('lg')]: {
            paddingLeft: "25rem",
            paddingRight: "25rem"
        },

        [theme.breakpoints.up('xl')]: {
            paddingLeft: "35rem",
            paddingRight: "35rem"
        }

    }


}));