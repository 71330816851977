import { UNETheme } from "../../Theme/UNETheme";
import { BaseStyleDefinition } from "./BaseStyleDefinition";


export class UNEStyleDefinition extends BaseStyleDefinition {

    constructor() {
        super(
            "une-style",
            UNETheme
        );
    }

}