import { makeStyles, Theme } from "@material-ui/core";


export const UniversidadLoaderStyle = makeStyles((theme: Theme) => ({

    bgContainer: {

        width: '100%',
        minHeight: '100vh',
        backgroundImage: `url(${process.env.PUBLIC_URL}/bgTexture.png)`,
        backgroundColor: theme.palette.primary.main

    },
    circularColor: {
        color: theme.palette.secondary.main
    },
    universidadLogo: {
        position: "relative",
        top: "150px"
    },
    defaultIcon: {
        color: "white",
        position: "relative",
        top: "11rem"
    },
    tipFormat: {
        color: "white",
        marginTop: "4rem",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        textAlign: "justify"
    }

}));