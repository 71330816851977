import { Fragment, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { LayoutLoader } from '../Components/LayoutLoader/LayoutLoader';
import { IRoute } from "./IRoute";




export const AppRouter = (routes: IRoute[] = []): JSX.Element => (

    <Suspense fallback={<LayoutLoader />}>
        <Switch>
            {
                routes.map((route, index) => {

                    const Layout = route.layout || Fragment;
                    const Component = route.component;

                    return (
                        <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            render={(props) => (
                                <Layout>
                                    {
                                        route.routes ? (
                                            AppRouter(route.routes)
                                        ) : (
                                            <Component {...props} />
                                        )
                                    }
                                </Layout>
                            )}
                        />
                    )

                })
            }
        </Switch>
    </Suspense>

);