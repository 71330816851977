import { IAPICarreraConnector } from "../Interfaces/Services/IAPICarreraConnector";
import { IAPIConfigCarreraConnector } from "../Interfaces/Services/IAPIConfigCarreraConnector";
import { IAPIContactoConnector } from "../Interfaces/Services/IAPIContactoConnector";
import { IAPICotizacionConnector } from "../Interfaces/Services/IAPICotizacionConnector";
import { IAPICRMConnector } from "../Interfaces/Services/IAPICRMConnector";
import { IAPIModalidadConnector } from "../Interfaces/Services/IAPIModalidadConnector";
import { IAPINivelEducativoConnector } from "../Interfaces/Services/IAPINivelEducativoConnector";
import { IAPIQueryDataConnector } from "../Interfaces/Services/IAPIQueryDataConnector";
import { IAPIQuickTipConnector } from "../Interfaces/Services/IAPIQuickTipConnector";
import { IAPIUniversidadConnector } from "../Interfaces/Services/IAPIUniversidadConnector";
import { APICarreraConnector } from "./APICarreraConnector";
import { APIConfigCarreraConnector } from "./APIConfigCarreraConnector";
import { APIContactoConnector } from "./APIContactoConnector";
import { APICotizacionConnector } from "./APICotizacionConnector";
import { APICRMConnector } from "./APICRMConnector";
import { APIModalidadConnector } from "./APIModalidadConnector";
import { APINivelEducativoConnector } from "./APINivelEducativoConnector";
import { APIQuickTipConnector } from "./APIQuickTipConnector";
import { APIUniversidadConnector } from "./APIUniversidadConnector";




export class APIQueryDataConnector implements IAPIQueryDataConnector {

    private _Universidades: IAPIUniversidadConnector;
    private _Contactos: IAPIContactoConnector;
    private _QuickTips: IAPIQuickTipConnector;
    private _NivelesEducativos: IAPINivelEducativoConnector;
    private _CarreraConfig: IAPIConfigCarreraConnector;
    private _Modalidad: IAPIModalidadConnector;
    private _Carreras: IAPICarreraConnector;
    private _Cotizacion: IAPICotizacionConnector;
    private _CRMConnector: IAPICRMConnector;


    constructor(baseUrl: string) {

        this._Universidades = new APIUniversidadConnector(baseUrl);
        this._Contactos = new APIContactoConnector(baseUrl);
        this._QuickTips = new APIQuickTipConnector(baseUrl);
        this._NivelesEducativos = new APINivelEducativoConnector(baseUrl);
        this._CarreraConfig = new APIConfigCarreraConnector(baseUrl);
        this._Modalidad = new APIModalidadConnector(baseUrl);
        this._Carreras = new APICarreraConnector(baseUrl);
        this._Cotizacion = new APICotizacionConnector(baseUrl);
        this._CRMConnector = new APICRMConnector(baseUrl);

    }

    public get CRMConnector(): IAPICRMConnector {
        return this._CRMConnector;
    }

    public set CRMConnector(v: IAPICRMConnector) {
        this._CRMConnector = v;
    }

    public get Cotizacion(): IAPICotizacionConnector {
        return this._Cotizacion;
    }
    public set Cotizacion(v: IAPICotizacionConnector) {
        this._Cotizacion = v;
    }


    public get Carreras(): IAPICarreraConnector {
        return this._Carreras;
    }
    public set Carreras(value: IAPICarreraConnector) {
        this._Carreras = value;
    }

    public get Modalidad(): IAPIModalidadConnector {
        return this._Modalidad;
    }
    public set Modalidad(value: IAPIModalidadConnector) {
        this._Modalidad = value;
    }

    public get CarreraConfig(): IAPIConfigCarreraConnector {
        return this._CarreraConfig;
    }
    public set CarreraConfig(value: IAPIConfigCarreraConnector) {
        this._CarreraConfig = value;
    }

    public get NivelesEducativos(): IAPINivelEducativoConnector {
        return this._NivelesEducativos;
    }
    public set NivelesEducativos(value: IAPINivelEducativoConnector) {
        this._NivelesEducativos = value;
    }

    public get Universidades(): IAPIUniversidadConnector {
        return this._Universidades;
    }
    public set Universidades(value: IAPIUniversidadConnector) {
        this._Universidades = value;
    }

    public get QuickTips(): IAPIQuickTipConnector {
        return this._QuickTips;
    }
    public set QuickTips(value: IAPIQuickTipConnector) {
        this._QuickTips = value;
    }

    public get Contactos(): IAPIContactoConnector {
        return this._Contactos;
    }
    public set Contactos(value: IAPIContactoConnector) {
        this._Contactos = value;
    }



}