import { useState } from "react";
import { SECTIONS_IDS } from "../../Enums/SectionsIds";
import { IUseCalculadoraRenderState } from "./IUseCalculadoraRenderState";




export const UseCalculadoraRender = (defaultSection: SECTIONS_IDS) => {

    const [currentSection, setCurrentSection] = useState<IUseCalculadoraRenderState>({
        CurrentSection: defaultSection
    });

    const UpdateSectionId = (newSection: SECTIONS_IDS) => {

        setCurrentSection({
            ...currentSection,
            CurrentSection: newSection
        });

    };

    return [
        currentSection,
        UpdateSectionId
    ] as const;

}