import axios from "axios";
import { IModalidadData } from "../Interfaces/Data/IModalidadData";
import { IAPIModalidadConnector } from "../Interfaces/Services/IAPIModalidadConnector";
import { APIBaseConnector } from "./APIBaseConnector";



export class APIModalidadConnector extends APIBaseConnector implements IAPIModalidadConnector {


    public async GetModalidadesByNivelEducativo(idNivelEducativo: string, idUniversidad: string): Promise<IModalidadData[]> {

        const response = await axios.get<IModalidadData[]>(
            this.CreateActionURL(`Modalidad/ByNivelEducativo/${idNivelEducativo}/universidad/${idUniversidad}`)
        );

        return response.data;

    }



}