import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faUser } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControl, Grid, InputAdornment, InputLabel, Link, OutlinedInput, Typography } from "@material-ui/core";
import { useContext } from "react";
import { AppContext } from "../../Contexts/AppContext/AppContext";




export const CalculadoraDatosContacto = () => {

    const { AppState, UpdateUserData } = useContext(AppContext);


    const handleChange = (property: string) => (event: React.ChangeEvent<HTMLInputElement>) => {

        UpdateUserData(property, event.target.value);

    };

    const {
        UserData,
        Universidad
    } = AppState;

    return (

        <>
            <Grid container spacing={5} style={{ marginTop: "0.2rem" }}>
                <Grid item container alignContent="center" alignItems="center" xs={12}>
                    <Grid item xs={12}>
                        <Typography variant="h5" style={{ textAlign: "center" }}>
                            ¿Estás listo para alcanzar una meta más?
                        </Typography>
                        <Typography variant="subtitle1" style={{ textAlign: "center" }}>
                            Comienza compartiéndonos los siguientes datos
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="nombre-field">Nombre y Apellido</InputLabel>
                        <OutlinedInput
                            id="nombre-field"
                            value={UserData.nombre}
                            onChange={handleChange('nombre')}
                            error={UserData.nombre === ""}
                            required
                            startAdornment={
                                <InputAdornment position="start">
                                    <FontAwesomeIcon icon={faUser} />
                                </InputAdornment>
                            }
                            inputProps={{
                                maxLength: 65
                            }}
                            labelWidth={170}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="tel-field">Whatsapp (10 digitos)</InputLabel>
                        <OutlinedInput
                            id="tel-field"
                            value={UserData.telefono}
                            error={UserData.telefono === "" || UserData.telefono.length < 10}
                            required
                            type="tel"
                            onChange={handleChange('telefono')}
                            inputProps={{
                                maxLength: 10
                            }}
                            startAdornment={
                                <InputAdornment position="start">
                                    <FontAwesomeIcon icon={faWhatsapp} />
                                </InputAdornment>
                            }
                            labelWidth={150}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={5} style={{ margin: "5rem" }}>
                <Grid item container xs={12} justifyContent="center" style={{ textAlign: "center" }}>
                    <Typography variant="body2">
                        Al continuar confirmo que he leido{'\u00A0'}
                        <Link href={Universidad.politicaURL} target="_blank">
                            la politica de privacidad.
                        </Link>
                    </Typography>
                </Grid>
            </Grid>
        </>

    );


};