import { Paper, Typography } from "@material-ui/core";
import { useContext } from "react";
import { AppContext } from "../../Contexts/AppContext/AppContext";
import { CalculadoraBannerPromocionStyles } from "./CalculadoraBannerPromocionStyles";




export const CalculadoraBannerPromocion = () => {

    const { AppState } = useContext(AppContext);

    const styles = CalculadoraBannerPromocionStyles();


    return (
        <>
            {
                AppState.Universidad.banners.map((banner, index) => (
                    <Paper key={`banner-id-${index}`} variant="outlined" className={styles.bannerStyles}>
                        <Typography variant="body1">
                            {banner.mensaje}
                        </Typography>
                    </Paper>
                ))
            }
        </>
    );

};