import { PotosinaTheme } from "../../Theme/PotosinaTheme";
import { BaseStyleDefinition } from "./BaseStyleDefinition";


export class PotosinaStyleDefinition extends BaseStyleDefinition {

    constructor() {
        super(
            "potosina-style",
            PotosinaTheme
        );
    }

}