import axios from "axios";
import { IAPICRMConnector } from "../Interfaces/Services/IAPICRMConnector";
import { APIBaseConnector } from "./APIBaseConnector";



export class APICRMConnector extends APIBaseConnector implements IAPICRMConnector {


    public async CreateLeadRecord(universidad: string, nombre: string, telefono: string, notas: string): Promise<void> {

        const response = await axios.post(
            this.CreateActionURL('CRMConnector'),
            {
                "universidad": universidad,
                "nombre": nombre,
                "telefono": telefono,
                "notas": notas
            }
        );

    }


}