import axios from "axios";
import { ICarreraData } from "../Interfaces/Data/ICarreraData";
import { IRecintoData } from "../Interfaces/Data/IRecintoData";
import { IAPICarreraConnector } from "../Interfaces/Services/IAPICarreraConnector";
import { APIBaseConnector } from "./APIBaseConnector";



export class APICarreraConnector extends APIBaseConnector implements IAPICarreraConnector {


    public async GetRecintosByCarrera(idCarrera: string, idUniversidad: string): Promise<IRecintoData[]> {

        const response = await axios.get<IRecintoData[]>(
            this.CreateActionURL(`CarreraRecintos/FindByCarrera/${idCarrera}/universidad/${idUniversidad}`)
        );

        return response.data;

    }


    public async GetCarreraByModalidad(idModalidad: string, idNivelEducativo: string, idUniversidad: string): Promise<ICarreraData[]> {

        const response = await axios.get<ICarreraData[]>(
            this.CreateActionURL(`Carrera/ByModalidad/${idModalidad}/nivel/${idNivelEducativo}/universidad/${idUniversidad}`)
        );

        return response.data;

    }


    public async GetRecintosByCarreraModalidad(idModalidad: string, idCarrera: string, idUniversidad: string): Promise<IRecintoData[]> {

        const response = await axios.get<IRecintoData[]>(
            this.CreateActionURL(`CarreraRecintos/ByModalidad/${idModalidad}/carrera/${idCarrera}/universidad/${idUniversidad}`)
        );

        return response.data;

    }



}