import { faGraduationCap } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonGroup, Grid, Paper, Typography } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { AppContext } from "../../Contexts/AppContext/AppContext";
import { GAnalReporter } from "../../Helpers/GAnalReporter";
import { GAEVENTNAMES } from "../../Model/GAEVENTNAMES";
import { GAReporterDataContanier } from "../../Model/GAReportDataContainer";
import { CalculadoraBannerPromocion } from "../CalculadoraBannerPromocion/CalculadoraBannerPromocion";
import { CalculadoraWhatsAppLink } from "../CalculadoraWhatsAppLink/CalculadoraWhatsAppLink";
import { CalculadoraResultDataStyles } from "./CalculadoraResultDataStyles";
import { ICalculadoraResultDataProps } from "./ICalculadoraResultDataProps";
import { ICarreraConfigData } from "../../Interfaces/Data/ICarreraConfigData";




export const CalculadoraResultData = ({
    Results
}: ICalculadoraResultDataProps) => {

    const { AppState } = useContext(AppContext);
    const [resultadoIndex, setResultadoIndex] = useState(0);
    const [detallePago, setDetallePago] = useState(Results[resultadoIndex]);

    const styles = CalculadoraResultDataStyles();

    const {
        showColegiatura,
        showPagoTotal,
        showPrimerPago
    } = AppState.Universidad;

    const updateResultadoSelection = (index: number, duracionRaw: number) => () => {

        GAnalReporter(
            GAEVENTNAMES.SELECCION_DURACION,
            new GAReporterDataContanier(
                AppState.Universidad.nombre,
                duracionRaw
            ).ToJSON()
        );
        setResultadoIndex(index);

    };

    const formatDuracion = (months: number): string => {

        const years = Math.floor(months / 12);
        const leftMonths = months % 12;
        const monthsText = leftMonths > 0 ? `${leftMonths} meses` : "";
        return `${years} años ${monthsText}`;

    }


    useEffect(() => {

        setDetallePago(Results[resultadoIndex]);

    }, [resultadoIndex]);

    // const detallePago = Results[resultadoIndex];
    console.info("Detalle pago", detallePago);

    return (

        <Grid container spacing={3} style={{ marginBottom: "3rem" }}>
            <Grid item xs={12} style={{ backgroundColor: "#8080800d", marginBottom: "1rem", marginTop: "1rem" }}>
                <Grid container>
                    <Grid item xs={4} md={3} style={{ textAlign: "center" }}>
                        <FontAwesomeIcon icon={faGraduationCap} size="5x" />
                    </Grid>
                    <Grid item xs={8} md={9} style={{ paddingLeft: "1rem" }}>
                        <Typography variant="h6">
                            {AppState.Carrera.nombre}
                        </Typography>
                        <Typography variant="body2">
                            {AppState.Modalidad.nombre}  {AppState.Campus !== null ? `- ${AppState.Campus.nombre}` : ""}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} container justifyContent="center" style={{ marginBottom: "0.5rem" }}>
                        <ButtonGroup color="primary" variant="outlined">
                            {
                                Results.map((item, index) => (
                                    <Button
                                        key={`dura-${index}`}
                                        variant={index === resultadoIndex ? "contained" : "outlined"}
                                        onClick={updateResultadoSelection(index, item.duracion)}
                                    >
                                        {formatDuracion(item.duracion)}
                                    </Button>
                                ))
                            }
                        </ButtonGroup>
                    </Grid>
                    {
                        showColegiatura &&
                        <Grid item xs={12}>
                            <Paper variant="outlined" className={styles.bannerStyles}>
                                <Typography variant="h6">
                                    Colegiatura
                                </Typography>
                                <Typography variant="h5">
                                    <NumberFormat
                                        thousandsGroupStyle="thousand"
                                        value={detallePago.colegiatura}
                                        prefix="$"
                                        displayType="text"
                                        decimalSeparator="."
                                        thousandSeparator={true}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                        allowNegative={true} />
                                </Typography>
                            </Paper>
                        </Grid>
                    }
                    {
                        showPrimerPago &&
                        <Grid item xs={12}>
                            <Paper variant="outlined" className={styles.bannerStyles}>
                                <Typography variant="h6">
                                    Primer Pago
                                </Typography>
                                <Typography variant="h5">
                                    <NumberFormat
                                        thousandsGroupStyle="thousand"
                                        value={detallePago.primerPago}
                                        prefix="$"
                                        displayType="text"
                                        decimalSeparator="."
                                        thousandSeparator={true}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                        allowNegative={true} />
                                </Typography>
                            </Paper>
                        </Grid>
                    }
                    {
                        showPagoTotal &&
                        <Grid item xs={12}>
                            <Paper variant="outlined" className={styles.bannerStyles}>
                                <Typography variant="h6">
                                    Pago Total
                                </Typography>
                                <Typography variant="h5">
                                    <NumberFormat
                                        thousandsGroupStyle="thousand"
                                        value={detallePago.costoTotal}
                                        prefix="$"
                                        displayType="text"
                                        decimalSeparator="."
                                        thousandSeparator={true}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                        allowNegative={true} />
                                </Typography>
                            </Paper>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <CalculadoraBannerPromocion />
                    </Grid>
                    <Grid item xs={12}>
                        <CalculadoraWhatsAppLink costoTotal={detallePago.costoTotal} />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "1.5rem", textAlign: "center" }}>
                        <Typography variant="body1">
                            Consulte términos y condiciones en la universidad.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

    );

};