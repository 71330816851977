import axios from "axios";
import { ICarreraConfigData } from "../Interfaces/Data/ICarreraConfigData";
import { IModalidadCarreraData } from "../Interfaces/Data/IModalidadCarreraData";
import { IAPIConfigCarreraConnector } from "../Interfaces/Services/IAPIConfigCarreraConnector";
import { APIBaseConnector } from "./APIBaseConnector";




export class APIConfigCarreraConnector extends APIBaseConnector implements IAPIConfigCarreraConnector {


    // public async GetConfigCarreraUniversidad(idUniversidad: string, idModalidad: string, idCarrera: string): Promise<ICarreraConfigData[]> {

    //     const response = await axios.get<ICarreraConfigData[]>(
    //         this.CreateActionURL(`ConfiguracionCarrera/ByResultRequest/${idUniversidad}/modalidad/${idModalidad}/carrera/${idCarrera}`)
    //     );

    //     return response.data;

    // }

    public async GetConfigCarreraUniversidad(idUniversidad: string, idModalidad: string, idCarrera: string, idRecinto: string): Promise<ICarreraConfigData[]> {

        const response = await axios.get<ICarreraConfigData[]>(
            this.CreateActionURL(`ConfiguracionCarrera/ByResultRequest/${idUniversidad}/modalidad/${idModalidad}/carrera/${idCarrera}/recinto/${idRecinto}`)
        );

        return response.data;

    }

}