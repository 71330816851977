import { Grid, Link } from "@material-ui/core";
import { useContext } from "react";
import { AppContext } from "../../Contexts/AppContext/AppContext";
import { IWithChildrenProp } from "../../Interfaces/General/IWithChildrenProp";
import './CalculadoraHeader.css';
import { CalculadoraHeaderStyle } from "./CalculadoraHeaderStyle";




export const CalculadoraHeader = ({ children }: IWithChildrenProp) => {

    const { AppState } = useContext(AppContext);
    const styles = CalculadoraHeaderStyle();

    const {
        Universidad
    } = AppState;

    return (
        <div className={styles.headerContainer}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={3} className="flex-centered-item">
                    <Link href={AppState.TargetURL as string} target="_blank">
                        <img src={`${process.env.PUBLIC_URL}${Universidad.logoUrl}`} alt="logo-text" />
                    </Link>
                </Grid>
                <Grid item xs={12} md={9} className="flex-centered-item" style={{ textAlign: "center" }}>
                    {children}
                </Grid>
            </Grid>
        </div>
    );

}