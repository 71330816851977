import { Typography } from "@material-ui/core";
import { CalculadoraHeader } from "../../Components/CalculadoraHeader/CalculadoraHeader";
import { CalculadoraQuestionLayoutStyle } from "./CalculadoraQuestionLayoutStyle";
import { ICalculadoraQuestionLayoutProps } from "./ICalculadoraQuestionLayoutProps";


export const CalculadoraQuestionLayout = ({ Title, children }: ICalculadoraQuestionLayoutProps) => {

    const styles = CalculadoraQuestionLayoutStyle();

    return (

        <>
            <CalculadoraHeader>
                <Typography variant="h6" style={{ color: "white" }}>
                    {Title}
                </Typography>
            </CalculadoraHeader>
            <div className={styles.mainAreaContent}>
                {children}
            </div>
        </>

    );

};