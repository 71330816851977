import { UniverGDLTheme } from "../../Theme/UniverGDLTheme";
import { BaseStyleDefinition } from "./BaseStyleDefinition";




export class UniverGDLStyleDefinition extends BaseStyleDefinition {

    constructor() {
        super(
            "univer-style",
            UniverGDLTheme
        );
    }

}