import React, { createContext, useState } from "react";
import { ICarreraData } from "../../Interfaces/Data/ICarreraData";
import { ILineaNegocioData } from "../../Interfaces/Data/ILineaNegocioData";
import { IModalidadData } from "../../Interfaces/Data/IModalidadData";
import { IQuickTipData } from "../../Interfaces/Data/IQuickTipData";
import { IRecintoData } from "../../Interfaces/Data/IRecintoData";
import { IUniversidadData } from "../../Interfaces/Data/IUniversidadData";
import { IUserCollectedData } from "../../Interfaces/Data/IUserCollectedData";
import { DefaultAppState } from "../../Model/DefaultAppState";
import { IAppContextHandler } from "./IAppContextHandler";
import { IAppContextProviderProps } from "./IAppContextProviderProps";
import { IAppContextState } from "./IAppContextState";




export const AppContext = createContext<IAppContextHandler>({} as IAppContextHandler);


export const AppContextProvider = ({ children }: IAppContextProviderProps) => {

    const [appState, setAppState] = useState<IAppContextState>(DefaultAppState);

    const updateLineaNegocio = (linea: ILineaNegocioData) => {

        setAppState({
            ...appState,
            LineaNegocio: linea
        });

    }

    const updateUniversidadData = (newData: IUniversidadData) => {

        setAppState({
            ...appState,
            Universidad: newData
        });

    };

    const updateUserData = (property: string, value: string) => {

        setAppState({
            ...appState,
            UserData: {
                ...appState.UserData,
                [property]: value
            }
        });

    };

    const updateModalidad = (newValue: IModalidadData) => {

        setAppState({
            ...appState,
            Modalidad: newValue
        });

    };

    const updateCarrera = (newValue: ICarreraData) => {

        setAppState({
            ...appState,
            Carrera: newValue
        });

    };

    const updatePlantel = (newValue: IRecintoData) => {

        setAppState({
            ...appState,
            Campus: newValue
        });

    };

    const updateQuickTips = (newValue: IQuickTipData[]) => {

        setAppState({
            ...appState,
            QuickTips: newValue
        });

    }


    const updateFullUserData = (newValue: IUserCollectedData) => {

        setAppState({
            ...appState,
            UserData: newValue
        });

    }

    const updateInitialData = (uni: IUniversidadData, tips: IQuickTipData[], targetUrl: string | null, originUrl: string | null) => {

        setAppState({
            ...appState,
            Universidad: uni,
            QuickTips: tips,
            TargetURL: targetUrl,
            OriginURL: originUrl
        });

    }

    const clearData = (): void => {

        console.info("Update State");
        setAppState((prevState) => {
            prevState.Campus.chainId = "";
            prevState.LineaNegocio.chainId = "";
            prevState.Carrera.chainId = "";
            return prevState;
        });

    };


    return (
        <AppContext.Provider value={{
            AppState: appState,
            ClearData: clearData,
            UpdateUniversidadData: updateUniversidadData,
            UpdateUserData: updateUserData,
            UpdateLineaNegocio: updateLineaNegocio,
            UpdateModalidad: updateModalidad,
            UpdateCarrera: updateCarrera,
            UpdatePlantel: updatePlantel,
            UpdateFullUserData: updateFullUserData,
            UpdateQuickTips: updateQuickTips,
            UpdateInitialData: updateInitialData

        }}>
            {children}
        </AppContext.Provider>
    );

};