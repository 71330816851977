import { makeStyles, Theme } from "@material-ui/core";



export const CalculadoraResultDataStyles = makeStyles((theme: Theme) => ({

    bannerStyles: {
        textAlign: "center",
        marginTop: "1rem",
        padding: "0.5rem",
        borderColor: "rgb(0, 85, 166)",
        minHeight: "6rem",
        display: "flex",
        flexDirection: "column"
    },
    bannerText: {
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
    }

}));