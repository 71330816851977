import { Theme, ThemeProvider } from "@material-ui/core";
import { createContext, useState } from "react";
import { IStyleDefinition } from "../../Interfaces/General/IStyleDefinition";
import { BritanicaStyleDefinition } from "../../Model/Styles/BritanicaStyleDefinition";
import { ColeVascoStyleDefinition } from "../../Model/Styles/ColeVascoStyleDefinition";
import { ICELStyleDefinition } from "../../Model/Styles/ICELStyleDefinition";
import { ITMStyleDefinition } from "../../Model/Styles/ITMStyleDefinition";
import { LamarStyleDefinition } from "../../Model/Styles/LamarStyleDefinition";
import { LucernaStyleDefinition } from "../../Model/Styles/LucernaStyleDefinition";
import { PotosinaStyleDefinition } from "../../Model/Styles/PotosinaStyleDefinition";
import { SantafeStyleDefinition } from "../../Model/Styles/SantaFeStyleDefinition";
import { UAAStyleDefinition } from "../../Model/Styles/UAAStyleDefinition";
import { UNEStyleDefinition } from "../../Model/Styles/UNEStyleDefinition";
import { UNIJVStyleDefinition } from "../../Model/Styles/UNIJVStyleDefinition";
import { UniverGDLStyleDefinition } from "../../Model/Styles/UniverGDLStyleDefinition";
import { DefaultTheme } from "../../Theme/DefaultTheme";
import { IStylerContextHandlers } from "./IStylerContextHandlers";
import { IStylerContextProviderProps } from "./IStylerContextProviderProps";
import { UNIDEPStyleDefinition } from "../../Model/Styles/UNIDEPStyleDefinition";




export const StylerContext = createContext<IStylerContextHandlers>({} as IStylerContextHandlers);

export const StylerContextProvider = ({ children }: IStylerContextProviderProps) => {

    const [currentStyler, setCurrentStyler] = useState<Theme>(DefaultTheme);

    const SupportedStyles: IStyleDefinition[] = [
        new LucernaStyleDefinition(),
        new UniverGDLStyleDefinition(),
        new LamarStyleDefinition(),
        new PotosinaStyleDefinition(),
        new UNEStyleDefinition(),
        new UAAStyleDefinition(),
        new ICELStyleDefinition(),
        new SantafeStyleDefinition(),
        new BritanicaStyleDefinition(),
        new UNIJVStyleDefinition(),
        new ITMStyleDefinition(),
        new ColeVascoStyleDefinition(),
        new UNIDEPStyleDefinition()
    ]

    const updateStyler = (styleName: string) => {

        console.info("Style", styleName);

        const newStyler = SupportedStyles.filter(style => {
            return style.nombre === styleName;
        });

        if (newStyler.length > 0) {
            setCurrentStyler(newStyler[0].GetTheme());
            return;
        }

        setCurrentStyler(DefaultTheme);

    }

    return (

        <StylerContext.Provider value={{ Styler: currentStyler, UpdateStyler: updateStyler }}>
            <ThemeProvider theme={currentStyler}>
                {children}
            </ThemeProvider>
        </StylerContext.Provider>

    );

}