import { CalculadoraUsuarioLayout } from "../layouts/CalculadoraUsuarioLayout/CalculadoraUsuarioLayout";
import { IRoute } from "./IRoute";



export const AppRoutes: IRoute[] = [

    {
        path: '/:idUniversidad',
        exact: true,
        layout: CalculadoraUsuarioLayout

    }

];