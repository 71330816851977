import { UAATheme } from "../../Theme/UAATheme";
import { BaseStyleDefinition } from "./BaseStyleDefinition";


export class UAAStyleDefinition extends BaseStyleDefinition {

    constructor() {
        super(
            "uaa-style",
            UAATheme
        );
    }

}