import axios from "axios";
import { IQuickTipData } from "../Interfaces/Data/IQuickTipData";
import { IAPIQuickTipConnector } from "../Interfaces/Services/IAPIQuickTipConnector";
import { APIBaseConnector } from "./APIBaseConnector";




export class APIQuickTipConnector extends APIBaseConnector implements IAPIQuickTipConnector {


    public async GetTips(idUniversidad: string): Promise<IQuickTipData[]> {

        const response = await axios.get(
            this.CreateActionURL(`QuickTip/ByUniversidad/${idUniversidad}`)
        );

        return response.data;

    }


}