import { faCheckCircle, faCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, Typography } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { APIContext } from '../../Contexts/APIContext/APIContext';
import { AppContext } from '../../Contexts/AppContext/AppContext';
import { GAnalReporter } from '../../Helpers/GAnalReporter';
import { ILineaNegocioData } from '../../Interfaces/Data/ILineaNegocioData';
import { GAEVENTNAMES } from '../../Model/GAEVENTNAMES';
import { GAReporterDataContanier } from '../../Model/GAReportDataContainer';
import { ILineaNegocioRenderState } from './ILineaNegocioRenderState';
import { LineaNegocioRenderStyle } from './LineaNegocioRenderStyle';



export const LineaNegocioRender = () => {


    const { AppState, UpdateLineaNegocio } = useContext(AppContext);
    const { APIService } = useContext(APIContext);
    const [lineaState, setLineaState] = useState<ILineaNegocioRenderState>({
        Lineas: [],
        IsLoading: false
    });

    const {
        Lineas,
    } = lineaState;

    const styles = LineaNegocioRenderStyle();


    const setSelected = (linea: ILineaNegocioData) => () => {

        UpdateLineaNegocio(linea);
        GAnalReporter(
            GAEVENTNAMES.SELECCION_LINEA_NEGOCIO,
            new GAReporterDataContanier(
                AppState.Universidad.nombre,
                linea.nombre
            ).ToJSON()
        );

    };

    const LoadLineasNegocio = async () => {

        const lineas = await APIService.NivelesEducativos.GetNivelesDisponibles(AppState.Universidad.chainId);
        setLineaState({
            Lineas: lineas,
            IsLoading: false
        });

    }

    useEffect(() => {

        LoadLineasNegocio();

    }, []);

    return (
        <Grid container className={styles.listContainer}>
            <Grid item xs={12} style={{ textAlign: "center", paddingBottom: "2rem" }}>
                <Typography variant="h6">
                    Selecciona el nivel educativo de tu interés.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <ImageList rowHeight={180}>
                    {
                        Lineas.map(linea => (
                            <ImageListItem key={linea.chainId} onClick={setSelected(linea)} className={
                                AppState.LineaNegocio.chainId === linea.chainId ?
                                    styles.buttonContainerSelected : styles.buttonContainer
                            }>
                                <Typography variant="h4" className={styles.textCentered}>
                                    {linea.nombre}
                                </Typography>
                                <ImageListItemBar
                                    className={styles.borderedBottom}
                                    actionIcon={
                                        AppState.LineaNegocio.chainId === linea.chainId ? (
                                            <IconButton onClick={setSelected(linea)} className={styles.selectedIcon}>
                                                <FontAwesomeIcon icon={faCheckCircle} />
                                            </IconButton>
                                        ) : (
                                            <IconButton onClick={setSelected(linea)} className={styles.notSelectedIcon}>
                                                <FontAwesomeIcon icon={faCircle} />
                                            </IconButton>
                                        )
                                    }
                                />
                            </ImageListItem>
                        ))
                    }
                </ImageList>
            </Grid>
        </Grid>

    );

};