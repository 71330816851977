import { ICELTheme } from "../../Theme/ICELTheme";
import { BaseStyleDefinition } from "./BaseStyleDefinition";

export class ICELStyleDefinition extends BaseStyleDefinition {

    constructor() {
        super(
            "icel-style",
            ICELTheme
        );
    }

}