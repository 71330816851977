import { faArrowAltLeft, faArrowAltRight, faGlobe } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Button, Typography } from "@material-ui/core";
import { useContext } from "react";
import { AppContext } from "../../Contexts/AppContext/AppContext";
import { CalculadoraButtonNavigatorStyle } from "./CalculadoraButtonNavigatorStyle";
import { ICalculadoraButtonNavigatorProps } from "./ICalculadoraButtonNavigatorProps";




export const CalculadoraButtonNavigator = ({
    ShowBackButton, ShowNextButton, ShowRegresarSitio,
    BackLabelText,
    OnBackEvent, OnNextEvent
}: ICalculadoraButtonNavigatorProps) => {

    const styles = CalculadoraButtonNavigatorStyle();
    const { AppState } = useContext(AppContext);

    const backLabelText: string = BackLabelText ? BackLabelText : "Regresar";

    return (

        <Grid container spacing={3} className={styles.navContainer}>
            <Grid item xs={5} md={4} container justifyContent="flex-start">
                {
                    ShowBackButton &&
                    <Button fullWidth variant="contained" color="default" className={styles.returnButton} onClick={OnBackEvent}>
                        <Typography variant="body1">
                            <FontAwesomeIcon icon={faArrowAltLeft} /> {backLabelText}
                        </Typography>
                    </Button>
                }
            </Grid>
            <Grid item xs={2} md={4}>
            </Grid>
            <Grid item xs={5} md={4} container justifyContent="flex-end">
                {
                    ShowNextButton &&
                    <Button className={styles.primaryButton} fullWidth variant="contained" color="primary" onClick={OnNextEvent}>
                        <Typography variant="body1">
                            <FontAwesomeIcon icon={faArrowAltRight} />  Siguiente
                        </Typography>
                    </Button>
                }
                {
                    ShowRegresarSitio &&
                    AppState.TargetURL &&
                    <Button
                        style={{ textAlign: "center" }}
                        href={AppState.TargetURL as string}
                        target="_blank"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={OnNextEvent}
                    >
                        <Typography variant="body1">
                            <FontAwesomeIcon icon={faGlobe} />  Regresar a {AppState.Universidad.nombre}
                        </Typography>
                    </Button>
                }
            </Grid>
        </Grid>

    );

}