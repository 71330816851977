import { createTheme } from "@material-ui/core";


export const ITMTheme = createTheme({
    palette: {
        primary: {
            main: "#E69553",
            contrastText: "#ffffff"
        },
        secondary: {
            main: "#7f0f06",
            contrastText: "#ffffff"
        },
        tertiary: {
            main: "#E69553",
        }
    },
    typography: {
        fontFamily: [
            'Krub',
            'sans-serif'
        ].join(',')
    }
});