import { SantaFeTheme } from "../../Theme/SantaFeTheme";
import { BaseStyleDefinition } from "./BaseStyleDefinition";


export class SantafeStyleDefinition extends BaseStyleDefinition {

    constructor() {
        super(
            'santafe-style',
            SantaFeTheme
        );
    }

}