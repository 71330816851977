import { createTheme } from "@material-ui/core";



export const PotosinaTheme = createTheme({
    palette: {
        primary: {
            main: "#cba05e",
            contrastText: "#fafafa"
        },
        secondary: {
            main: "#00346e",
            contrastText: "#1a237e"
        },
        tertiary: {
            main: "#02adbf",
        }
    },
    typography: {
        fontFamily: [
            'Krub',
            'sans-serif'
        ].join(',')
    }
});