



export class GAReporterDataContanier {

    uniData: string = "";
    value: any = null;

    constructor(uni: string, valor: any) {

        this.uniData = uni;
        this.value = valor;

    }


    public ToJSON() {

        return {
            "event_category": this.uniData,
            "event_label": this.value
        }

    }

}