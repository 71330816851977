import { ITMTheme } from "../../Theme/ITMTheme";
import { BaseStyleDefinition } from "./BaseStyleDefinition";


export class ITMStyleDefinition extends BaseStyleDefinition {

    constructor() {
        super(
            "itm-style",
            ITMTheme
        );
    }

}