import { makeStyles, Theme } from "@material-ui/core";



export const CalculadoraWhatsAppStyles = makeStyles((theme: Theme) => ({

    bannerStyles: {
        textAlign: "center",
        marginTop: "1rem",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        borderColor: "rgb(0, 85, 166)",
        minHeight: "6rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    bannerText: {
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
    },
    ButtonWhatsApp: {
        marginTop: "1rem"
    }

}));