import axios from "axios";
import { IAPICotizacionConnector } from "../Interfaces/Services/IAPICotizacionConnector";
import { APIBaseConnector } from "./APIBaseConnector";


export class APICotizacionConnector extends APIBaseConnector implements IAPICotizacionConnector {

    public async CreateUserCotizacion(idUsuario: string, idConfigCarrera: number): Promise<void> {

        const response = await axios.post(
            this.CreateActionURL('Cotizacion'),
            {
                "userId": idUsuario,
                "carreraId": idConfigCarrera
            }
        );

        console.info("Cotacto Cotizacion record", response);

    }

}