import { faChalkboardTeacher, faListAlt, faSearch } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, Grid, InputAdornment, List, ListItem, ListItemSecondaryAction, ListItemText, OutlinedInput, Radio, Typography } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { APIContext } from "../../Contexts/APIContext/APIContext";
import { AppContext } from "../../Contexts/AppContext/AppContext";
import { GAnalReporter } from "../../Helpers/GAnalReporter";
import { ICarreraData } from "../../Interfaces/Data/ICarreraData";
import { GAEVENTNAMES } from "../../Model/GAEVENTNAMES";
import { GAReporterDataContanier } from "../../Model/GAReportDataContainer";
import { CalculadoraCarreraFilterStyle } from "./CalculadoraCarreraFilterStyle";
import { ICalculadoraCarreraFilterState } from "./ICalculadoraCarreraFilterState";




export const CalculadoraCarreraFilter = () => {

    const classes = CalculadoraCarreraFilterStyle();

    const { APIService } = useContext(APIContext);
    const { AppState, UpdateCarrera } = useContext(AppContext);
    const [filterState, setFilterSatate] = useState<ICalculadoraCarreraFilterState>({
        Carreras: []
    });
    const [filterText, setFilterText] = useState<string>("");

    const {
        Modalidad,
        Carrera
    } = AppState;

    const {
        Carreras
    } = filterState;

    const LoadCarreras = async () => {

        const carreras = await APIService.Carreras.GetCarreraByModalidad(
            AppState.Modalidad.chainId,
            AppState.LineaNegocio.chainId,
            AppState.Universidad.chainId
        );

        setFilterSatate({
            Carreras: carreras
        });

    };

    useEffect(() => {

        if (Modalidad.chainId != "") {
            LoadCarreras();
        }

    }, [Modalidad])

    const updateFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterText(event.target.value);
    };

    const carreraChosen = (value: ICarreraData) => () => {

        UpdateCarrera(value);
        GAnalReporter(
            GAEVENTNAMES.SELECCION_CARRERA,
            new GAReporterDataContanier(
                AppState.Universidad.nombre,
                value.nombre
            ).ToJSON()
        );

    };


    const isCurrentSelection = (carrera: ICarreraData) => {

        return carrera.chainId === Carrera.chainId;

    }


    const renderCarreraList = (value: ICarreraData, index: number) => {

        const itemKey = `carrera-list-item-${index}`;
        const labelId = `carrera-list-label-${index}`;

        return (
            <div key={itemKey}>
                <ListItem className={isCurrentSelection(value) ? classes.itemContainerSelected : classes.itemContainer}>
                    <ListItemText id={labelId} primary={value.nombre} className={classes.listText} />
                    <ListItemSecondaryAction>
                        <Radio
                            edge="end"
                            checked={isCurrentSelection(value)}
                            onChange={carreraChosen(value)}
                            inputProps={{ 'aria-labelledby': labelId }}
                            name="carrera-radio-list"
                        />
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider />
            </div>
        );

    };

    const onFilteredRender = () => {

        const toFilter = Carreras.filter(item => {

            return item.nombre.toLowerCase().includes(filterText.toLowerCase());

        })

        if (toFilter.length > 0) {

            return toFilter.map((value, index) => {

                return renderCarreraList(value, index);

            });

        }

        return (
            <Grid container className={classes.noResultContainer}>
                <Grid item xs={12} className={classes.noDataContainer}>
                    <FontAwesomeIcon className={classes.circleIcon} size="6x" icon={faListAlt} />
                    <Typography variant="h6">
                        Sin resultados que mostrar.
                    </Typography>
                </Grid>
            </Grid>
        );


    }


    return (

        <>
            <Grid container>
                <Grid container item xs={12} justifyContent="center">
                    <Typography variant="h6">
                        Buscar {AppState.LineaNegocio.nombre}:
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <OutlinedInput
                        fullWidth
                        type="text"
                        value={filterText}
                        onChange={updateFilter}
                        startAdornment={
                            <InputAdornment position="start">
                                <FontAwesomeIcon icon={faSearch} />
                            </InputAdornment>
                        }
                    />
                </Grid>
            </Grid>
            <Grid container>
                {
                    AppState.Modalidad.chainId === "" ? (
                        <Grid container className={classes.noResultContainer}>
                            <Grid item container xs={12} className={classes.noDataContainer}>
                                <FontAwesomeIcon className={classes.circleIcon} size="6x" icon={faChalkboardTeacher} />
                                <Typography variant="h6">
                                    Seleccione la modalidad
                                </Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            <Grid container item xs={12} justifyContent="center" style={{ paddingTop: "1rem" }}>
                                <Typography variant="h6">
                                    ¿Qué carrera quieres estudiar?
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.listContainer}>
                                <List dense>
                                    {
                                        filterText !== "" ? (

                                            onFilteredRender()

                                        ) : (

                                            Carreras.map((value, index) => {

                                                return renderCarreraList(value, index);

                                            })

                                        )
                                    }
                                </List>
                            </Grid>
                        </>
                    )
                }
            </Grid>
        </>

    );

};