

export class GAEVENTNAMES {

    public static NUEVO_CONTACTO: string = "Nuevo Contacto";
    public static SELECCION_LINEA_NEGOCIO: string = "Seleccion Linea Negocio";
    public static SELECCION_MODALIDAD: string = "Seleccion Modalidad";
    public static SELECCION_PLANTEL: string = "Seleccion Plantel";
    public static SELECCION_CARRERA: string = "Seleccion Carrera";
    public static SELECCION_DURACION: string = "Seleccion Duracion";
    public static CLICK_WHATS_APP: string = "Click WhatsApp";
    public static CLICK_REGRESAR_SITIO: string = "Click Regresar Sitio";
    public static CLICK_COMENZAR: string = "Click Comenzar de Nuevo";

    public static PARAM_UNIVERSIDAD: string = "universidad_origen";
    public static PARAM_SELECION: string = "valor_seleccionado";

}